import { useState } from "react";
import { BiCheck } from "react-icons/bi";
import { creditTiers, monthlyTiers } from "../constants/pricing-tiers";

const PricingSection = () => {
  const [showCreditsSection, setShowCreditsSection] = useState(true);
  const [showMonthlySection, setShowMonthlySection] = useState(false);

  return (
    <div id="pricing" className="bg-white">
      <div className="mx-auto max-w-7xl py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:align-center sm:flex sm:flex-col">
          <h1 className="text-5xl font-bold tracking-tight text-gray-900 text-center">
            Pricing
          </h1>
          <p className="mt-5 text-xl text-gray-500 text-center">
            The right price for you, whoever you are.
          </p>
          <div className="relative mt-6 flex self-center rounded-lg bg-gray-100 p-0.5 sm:mt-8">
            <button
              type="button"
              title="Link Credits Packs"
              onClick={() => {
                setShowCreditsSection(true);
                setShowMonthlySection(false);
              }}
              className="relative w-1/2 whitespace-nowrap rounded-md border-gray-200 bg-white py-2 text-sm font-bold text-gray-900 shadow-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-purple-800 sm:w-auto sm:px-8"
            >
              Link Credits
            </button>
            <button
              type="button"
              title="Monthly Plans"
              onClick={() => {
                setShowCreditsSection(false);
                setShowMonthlySection(true);
              }}
              className="relative ml-0.5 w-1/2 whitespace-nowrap rounded-md border border-transparent py-2 text-sm font-bold text-gray-700 focus:z-10 focus:outline-none focus:ring-2 focus:ring-purple-800 sm:w-auto sm:px-8"
            >
              Quarterly Plans
            </button>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {showMonthlySection &&
            monthlyTiers.map((tier) => (
              <div
                key={tier.name}
                className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-lg"
              >
                <div className="p-6">
                  <div className="flex flex-row justify-between items-center">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      {tier.name}
                    </h2>
                    {tier.mostCommon && (
                      <h2 className="animate-bounce text-[10px] font-medium text-purple-800 bg-purple-200 rounded-full p-1 px-2 border border-purple-200 shadow-md">
                        Most Popular
                      </h2>
                    )}
                  </div>
                  <p className="mt-4 text-sm text-gray-500">
                    {tier.description}
                  </p>
                  <p className="mt-8">
                    <span className="text-4xl font-bold tracking-tight text-blu-900">
                      ${tier.price}
                    </span>{" "}
                    <span className="text-base font-medium text-gray-700">
                      /quarterly
                    </span>
                  </p>
                  <span className="text-sm font-medium text-gray-400">
                    equals ${Math.floor(tier.price / 4)} /mo
                  </span>
                  <a
                    href={tier.href}
                    className="mt-8 block w-full rounded-md border border-purple-800 bg-purple-800 py-2 text-center text-sm font-bold text-white hover:bg-purple-500 hover:border-purple-500"
                  >
                    Subscribe to {tier.name}
                  </a>
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className="text-sm font-medium text-gray-900">
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <BiCheck
                          className="h-5 w-5 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          {showCreditsSection &&
            creditTiers.map((tier) => (
              <div
                key={tier.name}
                className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-lg"
              >
                <div className="p-6">
                  <div className="flex flex-row justify-between items-center">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      {tier.name}
                    </h2>
                    {tier.mostCommon && (
                      <h2 className="animate-bounce text-[10px] font-medium text-purple-800 bg-purple-200 rounded-full p-1 px-2 border border-purple-200 shadow-md">
                        Most Popular
                      </h2>
                    )}
                  </div>
                  <p className="mt-8 text-center">
                    <span className="text-4xl font-bold tracking-tight">
                      {tier.credit}
                    </span>
                  </p>
                  <p className="mt-8 text-center">
                    <span className="text-xl font-normal tracking-tight">
                      ${tier.price}
                    </span>
                  </p>
                  <a
                    href={tier.href}
                    className="mt-8 block w-full rounded-md border border-purple-800 bg-purple-800 py-2 text-center text-sm font-bold text-white hover:bg-purple-500 hover:border-purple-500"
                  >
                    Buy {tier.credit}
                  </a>
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className="text-sm font-medium text-gray-900">
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <BiCheck
                          className="h-5 w-5 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
