import { useState } from "react";
import { useFormik } from "formik";

import * as Yup from "yup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SyncLoader } from "react-spinners";
import { BiCopy } from "react-icons/bi";
import axios from "../api/config";

import PricingSection from "../components/PricingSection";
import StatsSection from "../components/StatsSection";
import ContactSection from "../components/ContactSection";
import Footer from "../components/layout/Footer";

const HOUR = 1;
const MAX_LINKS_PER_HOUR = 3;

const LandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState();
  const [submitErrorMessage, setSubmitErrorMessage] = useState();
  const [linkCopied, setLinkCopied] = useState(false);

  const [limitReachedPerHour, setLimitReachedPerHour] = useState();

  let now = new Date().getTime();
  let setupTime = localStorage.getItem("setupTime");
  let linksCreated = +localStorage.getItem("linksCreated");

  const formik = useFormik({
    initialValues: {
      content: ``,
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      content: Yup.string()
        .required("Content required before generating a link.")
        .min(3, "At least 3 characters to be able to generate a link."),
    }),
    onSubmit: async (values, formikBag) => {
      setLoading(true);
      setSubmitMessage();
      setLimitReachedPerHour();
      setSubmitErrorMessage();

      if (setupTime == null || linksCreated == null) {
        localStorage.setItem("setupTime", now);
        localStorage.setItem("linksCreated", "0");
      } else {
        setupTime = localStorage.getItem("setupTime");
        linksCreated = +localStorage.getItem("linksCreated");

        // User already created free links within the hour
        if (
          now - setupTime < HOUR * 60 * 60 * 1000 &&
          linksCreated === MAX_LINKS_PER_HOUR
        ) {
          setLimitReachedPerHour(
            `Only ${MAX_LINKS_PER_HOUR} free links per hour. If you need to create more links now please buy one of our plans.`
          );
          setLoading(false);
          return;
        }
        // 1 hour has passed
        if (now - setupTime > HOUR * 60 * 60 * 1000) {
          localStorage.removeItem("setupTime");
          localStorage.removeItem("linksCreated");
        }
      }

      try {
        const res = await axios.post("/v1/link/new", values);

        setSubmitMessage(res.data.link);
        formikBag.resetForm();

        // Free usage is up to 3 links per hour.
        localStorage.setItem("linksCreated", ++linksCreated);
      } catch (error) {
        setSubmitErrorMessage(
          error.response.data.error ||
            "Something went wrong on our end. Please try again."
        );
      }
      setLoading(false);
    },
  });

  const showLinkCopied = () => {
    setLinkCopied(true);

    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  return (
    <>
      <div className="relative overflow-hidden bg-white mt-10">
        <div
          className="hidden sm:absolute sm:inset-y-0 sm:block sm:h-full sm:w-full"
          aria-hidden="true"
        >
          <div className="relative mx-auto h-full max-w-7xl">
            <svg
              className="absolute right-full translate-y-1/4 translate-x-1/4 transform lg:translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute left-full -translate-y-3/4 -translate-x-1/4 transform md:-translate-y-1/2 lg:-translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
              />
            </svg>
          </div>
        </div>

        <div id="home" className="relative pt-6 pb-16 sm:pb-24">
          <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline" title="Protect your data now">
                  Safely protect{" "}
                </span>
                <span className="block text-purple-800 xl:inline font-medium">
                  your content
                </span>
              </h1>
              <p className="mx-auto mt-3 max-w-md text-base text-gray-800 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
                Share and read text with a{" "}
                <span className="mx-auto max-w-md text-base text-purple-800 sm:text-lg md:max-w-3xl md:text-xl border-y border-purple-700">
                  one-time use link
                </span>{" "}
                that auto expires after viewing it.
              </p>

              <div className="mx-auto mt-5 max-w-md sm:flex flex-col sm:justify-center md:mt-8">
                <label htmlFor="hero-email" className="sr-only">
                  Content Section
                </label>
                <textarea
                  rows={6}
                  name="content"
                  id="content"
                  onChange={formik.handleChange}
                  value={formik.values["content"]}
                  onBlur={formik.handleBlur}
                  className="block w-full rounded-md border-gray-300 focus:border-purple-800 focus:ring-purple-800 sm:text-sm shadow-lg"
                  placeholder="Enter your text here, and we'll encrypt it for you."
                />
                <p className="text-gray-500 text-sm text-left">
                  characters: {formik.values["content"].length}
                </p>
                {formik.errors.content && (
                  <p className="text-black mt-4 font-medium">
                    {formik.errors.content}
                  </p>
                )}
                {limitReachedPerHour && (
                  <p className="text-red-700 mt-4 font-medium">
                    {limitReachedPerHour}
                  </p>
                )}
                {submitErrorMessage && (
                  <p className="text-red-700 mt-4 font-medium">
                    {submitErrorMessage}
                  </p>
                )}

                {submitMessage && (
                  <>
                    <p>Click link to copy</p>
                    <div className="flex flex-row justify-evenly">
                      <CopyToClipboard
                        text={submitMessage}
                        onCopy={showLinkCopied}
                      >
                        <button className="text-purple-900 mt-4 py-2 font-medium text-lg border-y border-purple-800">
                          {submitMessage}
                        </button>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={submitMessage}
                        onCopy={showLinkCopied}
                      >
                        <button>
                          <BiCopy size={20} className="mt-4 text-purple-900" />
                        </button>
                      </CopyToClipboard>
                    </div>
                    {linkCopied && (
                      <p className="text-green-800">Link Copied!</p>
                    )}
                  </>
                )}
                <div className="mt-10 w-full flex flex-row justify-center">
                  <button
                    title="Generate one-time use link"
                    type="button"
                    onClick={() => {
                      formik.handleSubmit();
                      setSubmitMessage();
                    }}
                    className="block w-60 rounded-md border border-transparent shadow-lg bg-purple-800 px-5 py-3 text-base font-bold text-white hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 sm:px-10"
                  >
                    {loading ? (
                      <SyncLoader size={10} color="#fff" />
                    ) : submitMessage ? (
                      "Generate New Link"
                    ) : (
                      "Generate Link"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <StatsSection />
      <PricingSection />
      <ContactSection />

      <Footer />
    </>
  );
};

export default LandingPage;
