export const monthlyTiers = [
  {
    name: "Basic",
    // href: "/signup?plan=basic&type=quarterly",
    // price: 36,
    href: "/",
    price: "TBD",
    description: "I am just getting started but I like it a lot",
    features: [
      "50 Characters Limit",
      "10 Links Per Day (300 max per month)",
      "Email Support",
    ],
  },
  {
    name: "Standard",
    // href: "/signup?plan=standard&type=quarterly",
    // price: 60,
    href: "/",
    price: "TBD",
    description: "Trial period ended and I know what I want",
    features: [
      "100 Characters Limit",
      "15 Links Per Day (450 max per month)",
      "Email Support",
    ],
  },
  {
    name: "Premium",
    // mostCommon: true,
    // href: "/signup?plan=premium&type=quarterly",
    // price: 180,
    href: "/",
    price: "TBD",
    description: "Improved features for the serious and demanding user",
    features: [
      "150 Characters Limit",
      "No Daily Limit For New Links",
      "1000 Links Per Month",
      "Email Support",
      "Priority Support",
    ],
  },
  {
    name: "Ultimate",
    // href: "/signup?plan=unlimited&type=quarterly",
    // price: 350,
    href: "/",
    price: "TBD",
    description: "Unlimited for the user that wants freedom and power",
    features: [
      "Unlimited Characters Limit",
      "No Daily Limit For New Links",
      "Unlimited Links Per Month",
      "Email Support",
      "Priority Support",
    ],
  },
];

export const creditTiers = [
  {
    name: "Basic",
    // href: "/signup?plan=basic&type=credit",
    // price: 10,
    href: "/",
    price: "TBD",
    description: "I am just getting started but I like it a lot",
    credit: "25 Credits",
    features: ["25 Links", "50 Characters Limit Each"],
  },
  {
    name: "Standard",
    // href: "/signup?plan=standard&type=credit",
    href: "/",
    price: "TBD",
    // price: 25,
    // mostCommon: true,
    description: "Trial period ended and I know what I want",
    credit: "75 Credits",
    features: ["75 Links", "100 Characters Limit Each"],
  },
  {
    name: "Premium",
    // href: "/signup?plan=premium&type=credit",
    // price: 60,
    href: "/",
    price: "TBD",
    description: "Improved features for the serious and demanding user",
    credit: "200 Credits",
    features: ["200 Links", "150 Characters Limit Each"],
  },
  {
    name: "Ultimate",
    // href: "/signup?plan=ultimate&type=credit",
    // price: 250,
    href: "/",
    price: "TBD",
    description: "Unlimited for the user that wants freedom and power",
    credit: "750 Credits",
    features: ["750 Links", "Unlimited Characters Limit Each"],
  },
];
