import { useState, useEffect, useCallback, Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { AiFillLock } from "react-icons/ai";
import axios from "../api/config";
import CopyToClipboard from "react-copy-to-clipboard";

const ViewLinkPage = () => {
  const { id } = useParams();
  const [linkReady, setLinkReady] = useState();
  const [linkContent, setLinkContent] = useState();
  const [loading, setLoading] = useState(true);
  const [contentCopied, setContentCopied] = useState(false);

  const checkLinkHandler = useCallback(async () => {
    try {
      const res = await axios.get("/v1/link/check?id=" + id);
      setLinkReady(res.data.valid);
    } catch (error) {
      setLinkReady(false);
    }
    setLoading(false);
  }, [id]);

  const openLinkHandler = async () => {
    setLoading(true);

    try {
      const res = await axios.get("/v1/link/open?id=" + id);
      setLinkContent(res.data.content);
    } catch (error) {
      setLinkContent(error);
    }
    setLoading(false);
  };

  const showContentCopied = () => {
    setContentCopied(true);

    setTimeout(() => {
      setContentCopied(false);
    }, 2000);
  };

  useEffect(() => {
    if (!linkReady) {
      checkLinkHandler();
    }
  }, [linkReady, checkLinkHandler]);

  const linkContentClass = linkContent
    ? "text-left p-4 block w-full min-h-[144px] rounded-md border border-purple-800 focus:border-purple-800 focus:ring-purple-800 sm:text-sm shadow-lg"
    : "text-left p-2 block w-full h-20 rounded-md border-4 border-gray-300 focus:border-purple-800 focus:ring-purple-800 sm:text-sm shadow-lg blur-sm";

  return (
    <Suspense fallback={<SyncLoader className="text-purple-800" size={25} />}>
      {!linkReady && (
        <div className="text-center">
          <h1 className="font-bold tracking-tight text-gray-900 text-3xl md:text-4xl">
            <span className="inline" title="Safely read">
              This link is{" "}
            </span>
            <span className="text-purple-800 xl:inline font-medium">
              expired
            </span>
          </h1>
          <p className="mx-auto mt-3 max-w-md text-base text-gray-800 md:mt-5 md:max-w-3xl">
            Either the link is invalid or someone already opened this{" "}
            <span className="mx-auto max-w-md text-base text-purple-800 md:max-w-3xl border-y border-purple-700">
              one-time use link
            </span>{" "}
            and it is no longer available.
          </p>

          <div className="mx-auto max-w-md sm:flex flex-col sm:justify-center mt-3 md:mt-8">
            <div className="w-full flex flex-row justify-center">
              <Link
                to="/"
                className="block w-60 rounded-md border border-transparent shadow-lg bg-purple-800 px-5 py-3 text-base font-bold text-white hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 sm:px-10"
              >
                Home
              </Link>
            </div>
          </div>
        </div>
      )}
      {linkReady && (
        <div className="text-center">
          <h1 className="font-bold tracking-tight text-gray-900 text-3xl md:text-4xl">
            <span className="inline" title="Safely read">
              Safely read{" "}
            </span>
            <span className="text-purple-800 xl:inline font-medium">
              this content
            </span>
          </h1>
          <p className="mx-auto mt-3 max-w-md text-base text-gray-800 md:mt-5 md:max-w-3xl">
            Someone sent you this{" "}
            <span className="mx-auto max-w-md text-base text-purple-800 md:max-w-3xl border-y border-purple-700">
              one-time use link
            </span>{" "}
            that will expire after viewing it.
          </p>

          <div className="mx-auto mt-5 max-w-md sm:flex flex-col sm:justify-center md:mt-8">
            <label htmlFor="hero-email" className="sr-only">
              Content Section
            </label>
            <CopyToClipboard text={linkContent} onCopy={showContentCopied}>
              <div name="content" id="content" className={linkContentClass}>
                {linkContent
                  ? `${linkContent}`
                  : "Sorry to disappoint but there is nothing here for you"}
              </div>
            </CopyToClipboard>
            {contentCopied && (
              <p className="text-green-800 my-2">Content Copied!</p>
            )}
            {!linkContent && (
              <div className="w-full flex justify-center items-center mt-6">
                <AiFillLock size={30} />
              </div>
            )}
            <div className="mt-6 w-full flex flex-row justify-center">
              {!linkContent ? (
                <button
                  title="Open Content"
                  type="button"
                  disabled={!linkReady}
                  onClick={openLinkHandler}
                  className="block w-60 rounded-md border border-transparent shadow-lg bg-purple-800 px-5 py-3 text-base font-bold text-white hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 sm:px-10"
                >
                  {loading ? (
                    <SyncLoader size={10} color="#fff" />
                  ) : (
                    "Read Content"
                  )}
                </button>
              ) : (
                <Link
                  title="Home"
                  to="/"
                  className="block w-60 rounded-md border border-transparent shadow-lg bg-purple-800 px-5 py-3 text-base font-bold text-white hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 sm:px-10"
                >
                  Home
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="relative bg-purple-800 rounded-md mt-10">
        <div className="relative mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 lg:py-6">
          <div className="md:mr-auto md:w-1/2 md:pr-10">
            <p className="mt-2 text-2xl font-bold tracking-tight text-white sm:text-3xl">
              What is SafelyRead.com?
            </p>
            <p className="mt-3 text-base text-gray-200">
              Safely Read was designed to help you share and read confidential
              and sensitive information. Whether it is personal information
              (SSN, DOB, Gender, etc), financial (Credit Card Number, Bank
              Account Number, etc), or secret keys (API keys, tokens, etc) we
              take care of encrypting your content. After your content is
              viewed, the link is expired which can never be retrieved again,
              ever.
            </p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                >
                  Create a one-time use link
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="h-56 sm:h-72 md:absolute md:top-0 md:right-0 md:h-full md:w-1/2 rounded-r-md">
          <img
            className="h-full w-full object-cover rounded-b-md sm:rounded-r-md"
            src="/images/lock.jpg"
            alt=""
          />
        </div>
      </div>
    </Suspense>
  );
};
export default ViewLinkPage;
