import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ViewLinkPage from "./pages/ViewLinkPage";
import NotFoundPage from "./pages/NotFoundPage";
import Layout from "./components/layout/Layout";

const App = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index path="/" element={<LandingPage />} />
        <Route path="/:id" element={<ViewLinkPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default App;
